<template>
    <div class="leftOne">
      <div class="chart-title">
        月度发布趋势分析
      </div>
      <div id="leftOneCharts">

      </div>

    </div>
  </template>

<script>
export default {
  name: 'leftOne',
  data() {
    return {
      option: null,
      myLeftOneCharts: null
    }
  },
  created() {
    if(!this.myLeftOneCharts) {
      this.$nextTick(async() => {
        this.option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            right: 0,
            top: 30,
            itemHeight: 10,
            textStyle: { color: '#fff', fontSize: 9},
            data: ['无线徐州', '张慧帮你问', '12345', '行风热线']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel:{
              textStyle: { color: '#fff'}
            },
            axisLine: {show: false},
            data: ['2月', '3月', '4月', '5月', '6月', '7月', '8月','9月','10月','11月','12月','1月','2月']
          },
          yAxis: {
            type: 'value',
            splitLine: { show: false},
            axisLabel:{
              textStyle: { fontSize: 9,color: '#fff'}
            },
            name: '万人次',
            nameLocation: 'end',
            nameTextStyle: { fontSize: 9,color: '#fff'}
          },
          series: [
            {
              name: '无线徐州',
              type: 'line',
              stack: 'Total',
              data: [120, 132, 101, 134, 90, 230, 256, 297, 310, 287, 302, 379, 394]
            },
            {
              name: '张慧帮你问',
              type: 'line',
              stack: 'Total',
              data: [220, 182, 191, 234, 290, 330, 310, 345, 330, 376, 410, 360, 400]
            },
            {
              name: '12345',
              type: 'line',
              stack: 'Total',
              data: [150, 232, 201, 154, 190, 330, 410, 400, 310, 389, 458, 412, 433]
            },
            {
              name: '行风热线',
              type: 'line',
              stack: 'Total',
              data: [320, 332, 301, 334, 390, 330, 320, 278, 233, 297, 378, 389, 400]
            },
          ]
        }
        this.myLeftOneCharts = this.$echarts.init(document.querySelector('#leftOneCharts'))
        this.myLeftOneCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myRightTherrCharts.resize()
    }
  }
}
</script>


<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

#leftOneCharts {
  width: 444px;
  height: 366px;
  background: url("../../assets/img/charts_bg_b.png");
  background-size: cover;
}

.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 447px;
  height: 40.19px;
  color: #E4F2FF ;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_two.png");
  background-size: cover;
  margin-bottom: 12px;
}

</style>