<template>
    <div class="rightOne">
      <div class="chart-title">
        热门话题排名
      </div>
      <div class="chart">
      <div class="header">
        <div class="news">新闻标题</div>
        <div>点赞数</div>
        <div>转发数</div>
      </div>
      <list-scroll class="box" :speed="1">
      <div class="list">
        <div class="content" v-for="(item, index) in list" 
          :key="index"
          :style="lineColor(index)">
          <div class="content-item item-title">
            {{ item.name }}
          </div>
          <div class="content-item item-num">
            {{ item.num1 }}
          </div>
          <div class="content-item item-num">
            {{ item.num2 }}
          </div>
        </div>
      </div>
    </list-scroll>
      </div>
    </div>
  </template>

  <script>
import ListScroll from "../../commonComponents/list.vue";
export default {
  name: "rightOne",
  components: {
    ListScroll
  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    // 获取积水点观察员数据
    let arr = []
    for (let i = 0; i < 10; i++) {
      arr.push({
        name: '四天小长假受到网页欢迎-“五一”将出现船级问题' ,
        num1: 999,
        num2: 999,
      })
    }
    this.list = arr
  },
  mounted() {

  },
  methods: {
    //前三行颜色
    lineColor(index) {
      let styleJson = {};
      if (index == 0) {
        styleJson["color"] = '#FFCA0F';
      } else if (index == 1){
        styleJson["color"] = '#E7FF54';
      } else if (index == 2){
        styleJson["color"] = '#80FF95';
      } else {
        styleJson["color"] = '#fff';
      }
      // styleJson["background-image"] = `url(static/logo-dp.png)`;
      return styleJson;
    },
  }
}
</script>


<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 59px;
}



.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 447px;
  height: 40.19px;
  color: #E4F2FF ;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_two.png");
  background-size: cover;
  margin-bottom: 12px;
}

.chart {
  width:447;
  height: 340;
  background: url("../../assets/img/charts_bg_b.png");
  background-size: cover;
  .header {
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4%;
    padding-left: 28px;
    padding-right: 28px;
    font-family: FZZ;
    font-size: 14px;
    font-weight: 700;

    .news {
      width: 300px;
    }
  }

  .box {
    width: 447px;
    height: 339px;
    overflow: hidden;
    margin-bottom: 10px;
    box-sizing: border-box;

  }

  .list {
    width: 100%;

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;

      .content-item {
        // margin-right: 12px;
        // width: calc((100% - 36px) / 4);
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        margin-bottom: 12px;
      }

      .item-title {
        width: 340px;
        position: relative;
        justify-content: flex-start !important;
        padding-left: 28px;
      }

      .item-num {
        width: 50px;
      }
    }

  }

}

</style>