<template>
  <div class="midOne">
    <div class="chart-title">
      热度视频
    </div>
    <div id="midOneCharts">
      <video src="https://video.xjl.nicewifi.cn/video/0799bf3dffa2a2bf4d19883c503fe62d.mp4" controls autoplay
        style="width: 650px;height: 299px;"></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'midOne',
}

</script>

<style>
.midOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
  padding-top: 60px;
}

#midOneCharts {
  width: 867px;
  height: 330px;
  background-image: url(../../assets/img/map_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-position: center; */
  background-position-y: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 866px;
  height: 40.19px;
  color: #E4F2FF;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_three.png");
  background-size: cover;
  margin-bottom: 12px;
}
</style>