<template>
    <div class="leftTwo">
      <div class="chart-title">
        用户互动数据分析
      </div>
      <div id="leftTwoCharts">
  
      </div>
    </div>
  </template>

<script>
export default {
  name: "leftTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftTwoCharts: null
    }
  },
  created() {
    if (!this.myLeftTwoCharts) {
      this.$nextTick(async() => {
        this.option = {
            tooltip: {
               trigger: 'axis',
               axisPointer: {
                 type: 'cross',
                 crossStyle: {
                   color: '#999'
                 }
               }
             },
             legend: {
                right: 10,
                top: 20,
                itemWidth:16,
                itemHeight: 6,
                textStyle: {
                    color: '#fff'
                },
                data: ['粉丝数','关注数','点赞数']
             },
             xAxis: [
               {
                 type: 'category',
                 data: ['无线徐州', '张慧帮你问', '12345', '行风热线',],
                 axisPointer: {
                   type: 'shadow'
                 },
                 axisLabel: {
                    color:'#fff',
                    fontSize: 10
                 },
                 axisLine: {
                  show: false
                 },
                 axisTick: {
                  show: false
                 }
               }
             ],
             yAxis: [
               {
                 type: 'value',
                 axisLabel: {
                   formatter: '{value} '
                 },
                 axisLabel: {
                    color:'#fff',
                    fontSize: 10
                 },
                 splitLine: { 
                    lineStyle: {
                      color: 'rgba(21, 119, 251, 0.37)',
                      type: 'dashed'
                    }
                 }
               },
               
             ],
             series: [
               {
                 name: '粉丝数',
                 type: 'bar',
                 label: {
                  show: true,
                  color: '#fff',
                  distance: -1,
                  position: 'top'
                 },
                 itemStyle: { 
                  color: 'rgba(59, 227, 233, 1)',
                  barBorderRadius:[5, 5, 0, 0]
                },
                 data: [
                   6554,5455,2544,5432
                 ]
               },
               {
                 name: '关注数',
                 type: 'bar',
                 label: {
                  show: true,
                  color: '#fff',
                  distance: -1,
                  position: 'top'
                 },
                 itemStyle: { 
                  color: 'rgba(255, 216, 79, 1)',
                  barBorderRadius:[5, 5, 0, 0]
                },
                 data: [
                   5452,545,643,3455
                 ]
               },
               {
                 name: '点赞数',
                 type: 'bar',
                 label: {
                  show: true,
                  color: '#fff',
                  distance: -1,
                  position: 'top'
                 },
                 itemStyle: { 
                  color: 'rgba(255, 91, 40, 1)',
                  barBorderRadius:[5, 5, 0, 0]
                },
                 data: [
                   4133, 1654, 2512,2544
                 ]
               },
             ]
        }
        this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftTwoCharts'))
        this.myLeftTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftTwoCharts.resize()
    }
  }
}
</script>


<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftTwo {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
}

#leftTwoCharts {
  width: 444px;
  height: 366px;
  background: url("../../assets/img/charts_bg_b.png");
  background-size: cover;
}

.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 447px;
  height: 40.19px;
  color: #E4F2FF ;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_two.png");
  background-size: cover;
  margin-bottom: 12px;
}
</style>