<template>
    <div class="rightTwo">
      <div class="chart-title">
        稿件类型分布分析
      </div>
      <div id="rightTwoCharts">

      </div>

    </div>
  </template>

<script>

export default {
  name: "rightTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      rRightTwoCharts: null
    }
  },
  created() {
    if (!this.rRightTwoCharts) {
      this.$nextTick(async () => {
        var data = [
          {value: 4230, name: '徐州'},
          {value: 4140, name: '社会'},
          {value: 4530, name: '美食'},
          {value: 4670, name: '房产'},
          {value: 4670, name: '家装'},
          {value: 4450, name: '健康'},
          {value: 400, name: '教育'},
          {value: 4760, name: '汽车'},
          {value: 4890, name: '车展'},
          {value: 4560, name: '国际'},
          {value: 4320, name: '旅游'},
          {value: 4450, name: '金融'},
          {value: 400, name: '积水点'},
          {value: 400, name: '音乐厅'},
          {value: 400, name: '其他'},
        ]
        var dataTotal = 0
        data.forEach(item => {
          dataTotal += +item.value
        })
        this.option = {
          color: ['#EF173F','#F43C1C','#FFAE50','#FCC115','#FFEA1A','#7FAB38',
                  '#29893C','#08794D','#064A8F','#0135A5','#332390','#312590','#6B1881','#B1126A','#AACEFF'],
          tooltip: {
            trigger: 'item'
          },
          title: {
            top: 'center',
            left: 'center',
            text: [
              "{a|稿件总量}",
              "{b|" + dataTotal + "}",
            ].join("\n"),
            textStyle: {
              width: "40",
              color: "#fff",
              rich: {
                a: {
                  color: "#fff",
                  fontSize: 23,
                  align: 'center',
                  fontWeight: "bold",
                  lineHeight: '30',
                },
                b: {
                  color: "#fff",
                  fontSize: 23,
                  fontWeight: "bold",
                  align: 'center',
                },
              },
            },
          },
          graphic: {
            type: 'circle',
            left: 'center',
            top: 'center',
            shape: {
              r: 55
            },
            style: {
              fill: 'rgba(255,255,255, 0)',
              stroke: 'rgba(6, 74, 143, 1)',
              lineDash: 'dotted',
              lineWidth: 2
            }
          },
          series: [
            {
              name: '热门板块分析',
              type: 'pie',
              radius: [60, 90],
              center: ['50%', '50%'],
              // roseType: 'area',
              // left: -165,
              itemStyle: {
                borderWidth: 0,
                borderColor:'#fff'
              },
              label: {
                position: 'outside',
                color: '#fff',
                padding: [-20,-40,-20,-40],
                rich: {
                  a: {
                    fontSize: 15,
                    fontStyle: 'bold',
                  },
                  b: {
                    fontSize: 12
                  }
                },
                // formatter: params => {
                //   return '{a|'+ params.name +'}'
                // }
                formatter: [
                  '{a|'+ '{b}' +'}',
                  `{b|总量：}`+ '{c}' +`{b|件}`,
                  `{b|占比：}`+ '{d}' +`{b|%}`
                ].join('\n')                  

              },
              
              itemStyle: {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 10
              },
              
              labelLine: {
                show: true,
                length: 30,
                length2: 50
              },
              labelLayout: {
                dy: 3,
                verticalAlign: 'bottom'
              },
              emphasis: {
                show: true
              },
              data: data
            }
          ]
        };
        this.rRightTwoCharts = this.$echarts.init(document.querySelector('#rightTwoCharts'))
        this.rRightTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.rRightTwoCharts.resize()
    }
  }
}
</script>


<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightTwo {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
}

#rightTwoCharts {
  width: 444px;
  height: 366px;
  background: url("../../assets/img/charts_bg_b.png");
  background-size: cover;
}

.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 447px;
  height: 40.19px;
  color: #E4F2FF ;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_two.png");
  background-size: cover;
  margin-bottom: 12px;
}


</style>