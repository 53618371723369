<template>
    <div class="midTwo">
      <div class="chart-title">
        平台分发渠道粉丝总量分析
      </div>
      <div id="midTwoCharts">

      </div>

    </div>
  </template>

<script>
import { data } from '../userViews/rightTwo.vue'

export default {
    name: 'midTwo',
    mounted() {
        this.initChart()
    },
    methods: {
        initChart() {
            let myCharts = this.$echarts.init(document.getElementById('midTwoCharts'))
            var  option = {
                color: ['#FF4C85','#FBFF4B','#42FF9D','#1CA0FF'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      // Use axis to trigger tooltip
                      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                  },
                  legend: {
                    top: 20,
                    right: 10,
                    itemWidth: 15,
                    itemHeight: 6,
                    itemStyle: {
                        width: 15,
                        height: 10
                    },
                    textStyle: {
                        color: '#B3CEFF',
                        fontSize: 10
                    },
                    data: ['抖音','视频号','快手','新聚浪']
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter:'{value}'+'w',
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#4B85F0',
                            type: 'dashed'
                        }
                    }
                  },
                  yAxis: {
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show:false
                    },
                    axisTick: {
                        show:false
                    },
                    axisLabel: {
                        fontSize: 10,
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    data: ['张慧帮你问', '无线徐州', '经济频道', '徐视第一房产', '徐州音乐厅', '徐州广电-健康有道', '徐州大健康','徐州新闻','快哉徐州','名城徐州']
                  },
                  series: [
                    {
                      name: '抖音',
                      type: 'bar',
                      stack: 'total',
                      label: {
                        show: true
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [70, 65, 63, 64, 60, 58, 54, 50, 48, 42]
                    },
                    {
                      name: '视频号',
                      type: 'bar',
                      stack: 'total',
                      label: {
                        show: true
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [50,50,48,46,47,43,40,39,37,35]
                    },
                    {
                      name: '快手',
                      type: 'bar',
                      stack: 'total',
                      label: {
                        show: true
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [22, 18, 19, 23, 21, 20, 19, 17, 15, 14,]
                    },
                    {
                      name: '新聚浪',
                      type: 'bar',
                      stack: 'total',
                      label: {
                        show: true
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [50,50,48,46,47,43,40,39,37,35]
                    },
                    {
                        name: '总计',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show:true,
                            position: 'right',
                            color: '#fff'
                        },
                        data: [0,0,0,0,0,0,0,0,0,0]
                    }
                  ]
            }
            var series = option.series
            function getSum (params) {
                let dataValue = 0
                for (let i= 0; i < series.length; i++) {
                    dataValue += series[i].data[params.dataIndex]
                }
                return dataValue + 'w'
            }
            series[series.length - 1].label.formatter = getSum 
            window.onresize = myCharts.resize;
            myCharts.setOption(option);
        }
    }   
}
</script>

<style>

.midTwo {
    display: flex;
    flex-direction: column;
    
}

#midTwoCharts {
    width: 867px;
    height: 418px;
    background: url("../../assets/img/charts_bg_b.png");
    background-size: cover;
}

.chart-title {
  font-size: 20.75px;
  font-family: FZZ;
  font-weight: 700;
  font-style: italic;
  width: 866px;
  height: 40.19px;
  color: #E4F2FF ;
  letter-spacing: 3.01px;
  padding-left: 57px;
  padding-top: 7px;
  background: url("../../assets/img/charts_title_three.png");
  background-size: cover;
  margin-bottom: 12px;
}


</style>